/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
import { getSchoolLists, geetestData, getWeixinCode } from '../http/api'
import loginImg from '../assets/img/image 22.png'

const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        account: profile && profile.account,
        name: profile && profile.name
    };
};
const regEmail = new RegExp("^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
// const useStyles = makeStyles({
//   progress: {
//     margin: theme.spacing(2),
//   },
// });

const modalStyle = {
    padding: '12px 25px 12px 25px',
    zIndex: 999999999,
    fontSize: 16,
    maxWidth: 300,
    minWidth: 100,
    color: 'rgba(255,255,255,1)',
    background: 'rgba(0,0,0,0.75)',
    borderRadius: 10,
    position: 'fixed',
    top: "20%",
    left: 0,
    right: 0,
    margin: '0px auto',
    textAlign: 'center',
    wordBreak: 'break-word'
}

@withRouter
class ResourcesLogin extends React.Component {
    // const [completed, setCompleted] = useState(0)
    constructor(props) {
        super(props)
        this.state = {
            login: true,
            schoolShow: false,  // 學校列表顯示
            schoolNum: '', //  学校邮箱编号
            list: [
            ],
            schoolName: '',   // 學校名稱
            schoolId: '',    // 選中的學校id
            value: '',  // 學校
            identityValue: 1,   // 選擇的身份
            identityShow: false,   // 身份選擇下拉列表
            userEmail: '',     // 用戶登錄郵箱
            userPassWord: '',    // 用戶登錄密碼
            progress: 0,         // 进度条
            title: '',
            progressShow: false,
            geetest_challenge: '',
            geetest_validate: '',
            geetest_seccode: '',
            isread: false,
            open: false,
            open2: false,
            type: "",
            errData: '',
            loginPageShow: 0,
            selectRole: 2,
            courseCode: "",
            getStudent: true,
            headerTitle:"欢迎登录！",
            studentList:["张三","李四","王五","哈萨尅","李柳","欧阳欧阳"],
            loginStudentName:""
        }
    }

    componentDidMount() {
        var _type = window.U.UF.C.queryString("type") || '';
        if (_type == 1 || _type == 2) {
            // window.location.href = "https://fangzhen.cocorobo.cn"
            this.setState({
                type: _type
            })
        }
        // var Height = document.documentElement.clientHeight
        // var font = document.getElementsByClassName('login')[0];
        // font.style.height = (Height - 150) + 'px';
        // function blue() {
        //     // font.style.color = 'blue';
        // }
        // font.onclick = function () {
        //     blue();
        // }
        document.getElementById('FOOTER').style.display = 'none';
        document.getElementById('NAV').style.display = 'none';
        //this.geetestRegister();
    }
    // url 取值
    getQueryString = (name) => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    }

    geetestRegister() {
        reset(CAPTCHA_NAME);
    }

    geetestValidate(isSuccess) {
        this.setState({
            geetest_challenge: isSuccess.geetest_challenge,
            geetest_validate: isSuccess.geetest_validate,
            geetest_seccode: isSuccess.geetest_seccode
        })
    }

    geetest = () => {
        return geetestData().then((res) => {
            var captcha = (res && res.data) || {};
            return captcha;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    // 獲取學校列表
    getSchoolList = e => {
        getSchoolLists({ mode: e }).then(res => {
            console.log(res.data[0])
            if (res.data[0].length > 0) {
                this.setState({
                    value: res.data[0][0].name,
                    schoolShow: false,
                    schoolId: res.data[0][0].id,
                    schoolNum: res.data[0][0].mail,
                })
            } else {
                this.setState({
                    value: '该学校编号不存在',
                    schoolShow: false,
                    schoolId: '',
                    schoolNum: '',
                })
            }
        })
    }

    progress = (n) => {
        let num = this.state.progress + n;
        num = num > 100 ? 0 : num
        this.setState({
            progress: num
        })
    }

    // 用戶登錄郵箱驗證
    loginEmail = e => {
        // 清楚首尾空格
        this.setState({
            userEmail: e.target.value
        })
    }

    // 用戶登錄密碼 
    loginPassWord = e => {
        this.setState({
            userPassWord: e.target.value
        })
    }


    // 根據輸入的值動態獲取學校列表
    changeList = e => {
        // 根據輸入的值動態獲取學校列表
        // e.target.value = e.target.value.replace(/[^\d]/g,'')
        console.log('e.target.value', e.target.value)
        this.setState({
            schoolName: e.target.value
        })
        if (e.target.value) {
            this.getSchoolList(e.target.value)
        } else {
            this.setState({
                value: '',
            })
        }
    }

    // 點擊學校列表，獲取學校名稱
    clickList = (e) => {
        // console.log(email)
    }

    // 點擊彈出身份列表
    identityShow = () => {
        const { identityShow } = this.state
        this.setState({
            identityShow: !identityShow
        })
    }

    // 登錄
    login = () => {
        const { language } = this.props
        // if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
        // /user
        const { userEmail, userPassWord } = this.state
        let a = '@szdjg.com'
        let email = ''
        let str = userEmail.replace(/(^\s*)|(\s*$)/g, "")
        if (!regEmail.test(str)) {
            email = str + a
        } else {
            email = str
        }
        // !regpass.test(userPassWord)
        if (userPassWord.length < 6) {
            window.U.Alert(language.password_regular);
            return;
        }
        this.setState({
            progressShow: true
        })
        let aa = setInterval(() => {
            this.progress(10)
        }, 100)
        let params = {
            geetest_challenge: this.state.geetest_challenge,
            geetest_validate: this.state.geetest_validate,
            geetest_seccode: this.state.geetest_seccode,
            loginUsername: email,
            loginPassword: userPassWord
        }
        axios.defaults.withCredentials = true;
        var _that = this;
        console.log(params)
        // return 
        axios.post(`${config.env.apiHk}api/user`, qs.stringify(params)).then(res => {
            _that.geetestRegister();
            if (res.data[0][0].active === 1) {
                _that.setState({
                    progressShow: false,
                    progress: 100
                }, () => {
                    window.U.Alert(`<p>${language.login_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
                })
                setTimeout(() => {
                    window.localStorage['identity'] = JSON.stringify(res.data[0][0].identity)
                    _that.props.toggleLogin(true)
                    if (_that.state.type == 1) {
                        var _UnityType = window.U.UF.C.queryString("UnityType") || '';
                        if (_UnityType) {
                            window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
                        } else {
                            window.location.href = "https://fangzhen.cocorobo.cn"
                        }
                    } else if (_that.state.type == 2) {
                        // window.location.href = "https://fangzhen.cocorobo.cn"
                    } else {
                        _that.props.history.push('/')
                    }
                    clearInterval(aa)
                }, 1000)
            } else {
                // window.U.Alert(language.login_fail);
                _that.setState({
                    progressShow: false,
                    errData: language.login_fail
                }, () => {
                    console.log(language.login_fail)
                    _that.setState({
                        isread: true
                    })
                    _that.closeModel()
                })
            }
        }).catch(err => {
            _that.geetestRegister();
            _that.setState({
                progressShow: false,
                errData: err.response.data || language.login_fail
            }, () => {
                console.log(err.response.data || language.login_fail)
                _that.setState({
                    isread: true
                })
                _that.closeModel()
            })
        })
    }

    closeModel = () => {
        let _this = this
        setTimeout(() => {
            _this.setState({
                isread: false
            })
        }, 2000)
    }
    enter = (e) => {
        if (e.keyCode === 13) {
            this.login()
        }
    }

    handleClose = () => {
        this.setState({
            isread: false
        })
    }

    // 选择用户角色
    select_role = (type) => {
        console.log(type)
        this.setState({
            // selectRole: type,
            loginPageShow:type
        })
    }

    // 通过班级码获取用户信息
    nextUserName = () => {
        console.log(this.state.courseCode)
        const {courseCode} = this.state

        if (courseCode === "") return
        this.setState({
            getStudent: false,
            headerTitle:"请选择您的姓名"
        })
    }
    selectStudentName=(name)=>{
        console.log(name)
        this.setState({
            loginStudentName:name
        })
    }
    render() {
        const { progressShow, userEmail, progress, userPassWord, isread, errData, loginPageShow, selectRole, courseCode, getStudent,headerTitle,studentList,loginStudentName } = this.state
        return (
            <div className="login_resources" onClick={(e) => {
                this.setState({
                    schoolShow: false,
                    identityShow: false
                })
            }}>
                <div className='contain' onKeyDown={this.enter}>
                    <div className='left_img'>
                        <img src={loginImg} ></img>
                    </div>
                    <div className='lognshadow'>
                        <div style={{ padding: '100px', marginTop: '25px' }}>
                            <div className="userLogin" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                            </div>
                            <div className='Login_h1'>
                                <p>{headerTitle}</p>
                                <div onClick={() => {
                                    console.log("关闭登录页")
                                }}>
                                    <span>×</span>
                                </div>
                            </div>
                            {loginPageShow === 0 && <div className=''>
                                <p className='p_title'>请选择登录角色</p>
                                <div className='select_role'>
                                    <div className={selectRole === 1 ? "select_active" : ""} onClick={() => this.select_role(1)}>
                                        <div>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.2969 5.57812C26.3288 5.57812 31.2188 10.468 31.2188 16.5C31.2188 20.3947 29.1802 23.8133 26.1118 25.7469V25.7464C29.8475 27.3133 32.8847 30.2153 34.6266 33.8555L41.5929 23.8744C42.0671 23.1951 43.0021 23.0288 43.6815 23.5028C44.354 23.9722 44.5238 24.8934 44.067 25.571L44.053 25.5914L36.0582 37.046C35.9913 37.1419 35.9136 37.2297 35.8264 37.3075C36.0935 38.485 36.2344 39.7104 36.2344 40.9688C36.2344 41.7972 35.5628 42.4688 34.7344 42.4688C33.9142 42.4688 33.2478 41.8105 33.2346 40.9936L33.2344 40.9688C33.2344 33.5129 27.1902 27.4688 19.7344 27.4688C12.3531 27.4688 6.35541 33.3926 6.2362 40.7455L6.23438 40.9688C6.23438 41.7972 5.5628 42.4688 4.73438 42.4688C3.90595 42.4688 3.23438 41.7972 3.23438 40.9688C3.23438 33.8485 7.74441 27.7817 14.0636 25.4691L14.0634 25.4695C11.2296 23.4965 9.375 20.2148 9.375 16.5C9.375 10.468 14.2649 5.57812 20.2969 5.57812ZM20.2969 8.57812C15.9218 8.57812 12.375 12.1249 12.375 16.5C12.375 20.8751 15.9218 24.4219 20.2969 24.4219C24.672 24.4219 28.2188 20.8751 28.2188 16.5C28.2188 12.1249 24.672 8.57812 20.2969 8.57812ZM43.2188 13.1719C44.0472 13.1719 44.7188 13.8435 44.7188 14.6719C44.7188 15.492 44.0605 16.1584 43.2436 16.1717L43.2188 16.1719H37.5938C36.7653 16.1719 36.0938 15.5003 36.0938 14.6719C36.0938 13.8518 36.752 13.1853 37.5689 13.1721L37.5938 13.1719H43.2188ZM43.2188 6.5625C44.0472 6.5625 44.7188 7.23408 44.7188 8.0625C44.7188 8.88263 44.0605 9.54905 43.2436 9.56231L43.2188 9.5625H33.6562C32.8278 9.5625 32.1562 8.89092 32.1562 8.0625C32.1562 7.24238 32.8145 6.57595 33.6314 6.56269L33.6562 6.5625H43.2188Z" fill="black" />
                                            </svg>
                                            <p>教师</p>
                                        </div>
                                    </div>
                                    <div className={selectRole === 2 ? "select_active" : ""} onClick={() => this.select_role(2)}>
                                        <div>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23171 34.3357C7.77909 34.1786 6.48177 34.0663 5.60693 34.0038C4.78061 33.9448 4.0629 34.5668 4.00388 35.3931C3.94485 36.2195 4.56687 36.9372 5.39319 36.9962C6.40933 37.0688 8.04552 37.2134 9.82611 37.4213C12.0643 37.6826 14.4587 38.0358 16.1363 38.4552C19.2155 39.225 22.9257 40.7195 24.3909 41.3707C24.7457 41.5284 25.1478 41.5424 25.5127 41.4097C27.3234 40.7512 31.8193 39.2434 35.3255 38.4643C36.9179 38.1104 38.6493 37.8077 40.2266 37.5667C42.1198 37.2775 43.7672 37.0808 44.6493 36.9926C45.4736 36.9101 46.0751 36.1751 45.9926 35.3507C45.9102 34.5264 45.1751 33.925 44.3508 34.0074C43.5761 34.0849 42.2795 34.2375 40.7553 34.4559C39.6545 32.7924 37.8888 30.7004 35.5826 28.9093C32.8401 26.7793 29.233 25 25.0001 25C20.7743 25 17.1703 26.7481 14.4267 28.845C12.1067 30.6182 10.3326 32.6918 9.23171 34.3357ZM12.6405 34.7614C14.157 34.98 15.6523 35.2419 16.8639 35.5448C19.8372 36.2881 23.2666 37.6262 25.0535 38.3878C27.1705 37.6408 31.3133 36.2827 34.6747 35.5357C35.5779 35.335 36.5165 35.1514 37.4428 34.986C36.5202 33.7997 35.2683 32.4637 33.7425 31.2787C31.3434 29.4154 28.3671 28 25.0001 28C21.6258 28 18.6465 29.3957 16.2484 31.2286C14.7721 32.3569 13.5528 33.6239 12.6405 34.7614Z" fill="black" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25 25C28.866 25 32 21.866 32 18C32 14.134 28.866 11 25 11C21.134 11 18 14.134 18 18C18 21.866 21.134 25 25 25ZM25 28C30.5228 28 35 23.5228 35 18C35 12.4772 30.5228 8 25 8C19.4772 8 15 12.4772 15 18C15 23.5228 19.4772 28 25 28Z" fill="black" />
                                            </svg>
                                            <p>学生</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {loginPageShow === 1 && <div className=''>
                                <p className='p_title'>手机号</p>
                                <div className="userLogin">
                                    <img src={require("../assets/img/email.png")} />
                                    <input placeholder='手机号' onBlur={this.loginEmail} value={userEmail} onChange={e => {
                                        this.setState({
                                            userEmail: e.target.value
                                        })
                                    }} />
                                </div>
                                <p className='p_title'>密码</p>
                                <div className="userLogin">
                                    <img src={require("../assets/img/password.png")} />
                                    <input placeholder="密码" type="password" onChange={this.loginPassWord} value={userPassWord} />
                                </div>
                                <div style={{marginTop: 20 }}>
                                    <div className="loginUser">
                                        <button onClick={this.login}>确认登录</button>
                                    </div>
                                </div>
                            </div>}
                            {loginPageShow === 2 && <div className='course_code'>
                            {getStudent ?<div className="userLogin">
                                    <p className='p_title'>课程识别码</p>
                                    <input className='course_input' placeholder='课程识别吗' value={courseCode} onChange={e => {
                                        console.log(!isNaN(Number(e.target.value)))
                                        if (isNaN(Number(e.target.value))) return
                                        this.setState({
                                            courseCode: e.target.value
                                        })

                                    }} />
                                    <button className='next_button' onClick={this.nextUserName}>下一步</button>
                                </div>:
                                <div className="loginUser">
                                    <div className='student_list'>
                                        {studentList.length>0&&studentList.map(x=>{
                                            return <span className={loginStudentName===x?"select_active":""} key={x} onClick={()=>this.selectStudentName(x)}>{x}</span>
                                        })}
                                    </div>   
                                    <button onClick={this.login}>确认登录</button>
                                </div>}
                            </div>
                            }
                            <div style={{ display: 'none' }}>
                                <Link to='/password'><p>忘記密碼？</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Popover
                    // id={id}
                    open={progressShow}
                    anchorOrigin={{
                        vertical: 200,
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ padding: '20px 30px', fontSize: 20 }}>
                        <CircularProgress style={{ display: 'block', margin: '0 auto' }} variant="static" value={progress} />
                    </div>
                </Popover>
                {isread && <div style={modalStyle}>
                    {errData || '用户不存在'}
                </div>}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesLogin);
